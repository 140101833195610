// src/AppTracking/trackUserActivity.js
import { trackUser } from './userHelper';
import axios from 'axios';
import { clearTrackingData } from './localStorageHelper'; 

export const incrementActiveUsersCount = () => {
  const activeUsersCount = sessionStorage.getItem('activeUsersCount');
  if (activeUsersCount) {
    const newCount = parseInt(activeUsersCount, 10) + 1;
    sessionStorage.setItem('activeUsersCount', newCount.toString());
  } else {
    sessionStorage.setItem('activeUsersCount', '1');
  }
};

export const decrementActiveUsersCount = () => {
  const activeUsersCount = sessionStorage.getItem('activeUsersCount');
  if (activeUsersCount) {
    const newCount = parseInt(activeUsersCount, 10) - 1;
    sessionStorage.setItem('activeUsersCount', Math.max(newCount, 0).toString());
  }
};

export const getActiveUsersCount = () => {
  return parseInt(sessionStorage.getItem('activeUsersCount') || '0', 10);
};

export const trackUserActivity = () => {
  const userId = trackUser();
  if (!sessionStorage.getItem('sessionActive-' + userId)) {
    incrementActiveUsersCount();
    sessionStorage.setItem('sessionActive-' + userId, 'true');
  }

  // Track landing page
  const trackLandingPage = () => {
    const url = window.location.href;
    const landed = url.substring(url.lastIndexOf('/') + 1);
    sessionStorage.setItem('landed', landed);
  };

  trackLandingPage();

  window.addEventListener('beforeunload', () => {
    decrementActiveUsersCount();
    sessionStorage.removeItem('sessionActive-' + userId);
  });

  window.addEventListener('popstate', trackLandingPage);
  window.addEventListener('pushState', trackLandingPage);
};

// Function to send session data to the API
const sendSessionDataToAPI = async () => {
  try {
    const sessionData = {
      sessionActive: sessionStorage.getItem('sessionActive-1') === 'true',
      userId: sessionStorage.getItem('userId'),
      trackingData: JSON.parse(sessionStorage.getItem('trackingData')) || [],
      trafficSource: sessionStorage.getItem('trafficSource'),
      activeUsersCount: sessionStorage.getItem('activeUsersCount'),
      totalUsers: sessionStorage.getItem('totalUsers'),
      landed: sessionStorage.getItem('landed')  // Retrieve the landed value from sessionStorage
    };

    const response = await axios.post('https://your-api-endpoint.com/track', sessionData);
    console.log('Data successfully sent to the API', response.data);

    clearTrackingData();
  } catch (error) {
    console.error('Error sending data to the API', error);
  }
};

export { sendSessionDataToAPI };
