import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackEvent } from '../ReactGA4';
import { Events, animateScroll as scroll } from 'react-scroll';

const ScrollTracking = () => {
  const location = useLocation();

  useEffect(() => {
    Events.scrollEvent.register('begin', () => {
    });

    Events.scrollEvent.register('end', () => {
    });

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  useEffect(() => {
    scroll.scrollToTop({
      duration: 100,
    });
  }, [location.pathname]);

  const handleScroll = () => {
    const scrollPercentage = (window.scrollY / document.body.scrollHeight) * 100;
    trackEvent('Scroll Percentage', { percentage: scrollPercentage });
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const trafficSource = urlParams.get('http://localhost:3000/godrej-woodsville?utm_source=Google&utm_medium=cpc&utm_campaign=villa-030224&utm_term={keyword}&utm_device={device}') || 'Direct';
    sessionStorage.setItem('trafficSource', trafficSource);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return null;
};

export default ScrollTracking;
