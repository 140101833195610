import React, { useEffect, useState } from "react";
import { BsHeartFill } from "react-icons/bs";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { MdVerified } from "react-icons/md";
import { HiOutlineTag, HiOutlineArrowUpOnSquare } from "react-icons/hi2";
import { LuBuilding } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa";
import { FaFire } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
const PropertyCard = ({ property }) => {
  const [cityList, setCityList] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  useEffect(() => {
    Api.get("/adminproperties/getCity")
      .then((response) => {
        setCityList(response.data.citylist);
      })
      .catch((error) => console.error("Error fetching city list:", error));
  }, []);

  const getCityName = (cityId) => {
    const city = cityList.find((city) => city.id === cityId);
    return city ? city.city_Name : "Unknown";
  };

  const formatPrice = (price) => {
    const numericPart = price.replace(/[^\d.]/g, "");
    const priceNumber = parseFloat(numericPart);
    const crore = 10000000;
    const lakh = 100000;
    if (priceNumber >= crore) {
      return `${(priceNumber / crore).toFixed(1)} Cr`;
    } else if (priceNumber >= lakh) {
      return `${(priceNumber / lakh).toFixed(1)} L`;
    } else if (priceNumber >= 1000) {
      return `${(priceNumber / 1000).toFixed(2)} T`;
    } else {
      return price;
    }
  };

  const getLowestAndHighestPrice = (pricing) => {
    const pricingArray = JSON.parse(pricing);
    let lowestPrice = pricingArray[0]?.price;
    let highestPrice = pricingArray[0]?.price;
    try {
      pricingArray.forEach((item) => {
        const price = parseInt(item.price);
        if (!isNaN(price)) {
          if (price < lowestPrice) {
            lowestPrice = price;
          }
          if (price > highestPrice) {
            highestPrice = price;
          }
        }
      });
    } catch (error) {
      console.error("Error parsing pricing data:", error);
    }
    return {
      lowestPrice: lowestPrice === Infinity ? null : lowestPrice,
      highestPrice: highestPrice === -Infinity ? null : highestPrice,
    };
  };

  const { lowestPrice, highestPrice } = getLowestAndHighestPrice(
    property.pricing
  );
  const storedUserId = sessionStorage.getItem("user");
  let userId;
  if (storedUserId) {
    const userData = JSON.parse(storedUserId);
    userId = userData.id;
  }
  const pricingArray = JSON.parse(property.pricing);
  const unitTypes = pricingArray.map((pricingObj) =>
    pricingObj.unit_type.toUpperCase()
  );
  const sortedUnitTypes = unitTypes
    .map((unitType) => {
      const parsedUnitType = parseFloat(unitType);
      return !isNaN(parsedUnitType) ? parsedUnitType : unitType;
    })
    .sort();
  const isFavorite = favorites.includes(property.id);
  const handleFavoriteSave = async (propertyId) => {
    let action = "save";
    if (favorites.includes(propertyId)) {
      setFavorites(favorites.filter((id) => id !== propertyId));
      action = "remove";
    } else {
      setFavorites([...favorites, propertyId]);
    }
    try {
      const response = await Api.post("/usersrouts/savingPropertiesOfUser", {
        userId: userId,
        propertyId,
        action,
      });
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error saving or removing property:", error);
      toast.error(error.response?.data?.error || "An error occurred");
      setShowLoginPrompt(true);
    }
  };
  return (
    <div
      className={`grid w-[280px] h-[313px] md:w-[313px] md:h-[313px]   mx-2 mt-5 border-[1px] border-gray-400  flex flex-row   rounded-xl  `}
      key={property.id}
    >
      <Link
        to={`/properties/${property?.cityProperties?.city_Name}/${
          property?.propertylocaation?.pramotional_Location
        }/${
          property?.propertylocaation?.actual_Location
        }/${property.project_Name.replace(/\s+/g, "-")}/${property.id}`}
        key={property.id}
      >
        <div className=" rounded-xl ">
          <div className=" gap-2 mb-1 lg:mb-0 ">
            {property.image_URL && (
              <div>
                <div className="relative  top-0  cursor-pointer flex flex-row justify-between w-full ">
                  {/* <div className="absolute bottom-0  shrink-0 left-0 top-3 text-black items-center">
                    <img
                      src={property.builderproperty.image}
                      alt={property.builderproperty.builder_name}
                      className="ml-2  w-[7rem] h-[32px] bg-white rounded-lg object-cover text-sm p-1 "
                    />
                  </div> */}
                    <div className="absolute bottom-0 left-0 top-3 text-black justify-center items-center flex p-2 h-10">
                            <img
                              src={property.property_logo}
                              alt={property.project_Name}
                              className="mx-2  w-12 h-10 bg-white rounded-lg m-5 text-sm items-center "/>
                          </div>
                  <div className="absolute top-0 right-0 m-3">
                    {isFavorite ? (
                      <div className="bg-gray-800 p-2 rounded-full flex items-center justify-center">
                        <BsHeartFill
                          color="red"
                          size={20}
                          onClick={(e) => {
                            e.preventDefault();
                            handleFavoriteSave(property.id);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="bg-white  rounded-full p-2 flex items-center justify-center">
                        <BsHeartFill
                          color="gray"
                          size={16}
                          onClick={(e) => {
                            e.preventDefault();
                            handleFavoriteSave(property.id);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <img
                  src={
                    Array.isArray(property.image_URL)
                      ? property.image_URL[0]
                      : typeof property.image_URL === "string"
                      ? JSON.parse(property.image_URL)[0]
                      : property.image_URL
                  }
                  alt={property.project_Name}
                  className=" w-full  rounded-t-xl mb-2 h-52 "
                />
              </div>
            )}
          </div>
          <div className="mb-2 mx-4 pb-2  ">
            <div className="grid grid-cols-5  ">
              <h3 className="text-[12px] md:text-sm lg:text-md font-bold col-span-3 line-clamp-1">
                {property.project_Name}
              </h3>
              <p className=" text-[12px] md:text-sm lg:text-md  font-bold line-clamp-1 col-span-2">
                &#8377;
                {lowestPrice === highestPrice
                  ? formatPrice(lowestPrice.toString())
                  : `${formatPrice(lowestPrice.toString())} - ${formatPrice(
                      highestPrice.toString()
                    )}`}
         
              </p>
            </div>
            <div className="grid    ">
              <p className="text-start text-xs font-medium ">
                {Array.from(new Set(sortedUnitTypes)).join(" , ")} BHK{" "}
                {property.propertylocaation.actual_Location}
              </p>
            </div>
            <div className="grid grid-flow-col gap-1  pt-2 lg:gap-2 justify-start text-xs text-[#065279]">
                          <p className="grid grid-flow-col justify-center items-center md:px-[2px] lg:pl-2 lg:pr-3 lg:py-1 bg-gradient-to-r from-cyan-100 to-blue-200  rounded-full">
                            <FaFire className="mx-1  text-[#F2A221]" />
                           {property.Tags.split(" ")[0]}
                          </p>
                          <p className="grid grid-flow-col justify-center items-center md:px-[2px] lg:px-2 lg:py-1 bg-gradient-to-r from-cyan-100 to-blue-200  rounded-full">
                            <MdVerified className="mx-1 text-[#057748]" />
                           {
                            property.rera ?  "RERA certfied" :"N/A"
                           } 
                          </p>
                        </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PropertyCard;
