import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../authentication/AuthContext";
import {
  Bars3Icon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Logo from "../Assets/Luxury&me.png";
import TagManager from "react-gtm-module";
import BrokerView from "../Broker/BrokerView";

const tagManagerArgs = {
  gtmId: "GTM-5VMZ349N",
};

TagManager.initialize(tagManagerArgs);

const Navbar = ({ onCitySelect, cities, selectedCity }) => {
  const { user, signOut, broker, logoutBroker } = useAuth();
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isBrokerViewOpen, setBrokerViewOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleProfileIconClick = () => {
    if (broker) {
      setBrokerViewOpen(true);
    } else {
      setProfileMenuOpen(!isProfileMenuOpen);
    }
  };
  const handleCitySelect = async (city) => {
    onCitySelect(city);
  };
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      setProfileMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavLinkClick = (to) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        linkUrl: to,
      },
    });
    navigate(to);
  };

  useEffect(() => {
    const handleScroll = () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "scrollEvent",
        scrollDepth: window.scrollY,
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigation = [
    // { name: "Home", to: "/", current: location.pathname === "/" },
    // {
    //   name: "Contact Us",
    //   to: "/Contact-Us",
    //   current: location.pathname === "/Contact-Us",
    // },
    // { name: "About Us", to: "/about", current: location.pathname === "/about" },
    // { name: "Blogs", to: "/blog", current: location.pathname === "/blog" },
    {
      name: "Broker Login",
      to: "/broker/login",
      current: location.pathname === "/broker/login",
      show: !(user || broker),
    },
    {
      name: "Signin",
      to: "/login",
      current: location.pathname === "/login",
      show: !(user || broker),
    },
  ];

  const profileMenuContent = (
    <div className="absolute right-0 mt-2 bg-black border rounded-md py-2 w-48 z-20">
      <button
        onClick={() => {
          handleNavLinkClick(user ? "/profile" : "/BrokerProfile");
          setProfileMenuOpen(false);
        }}
        className="block px-4 py-2 text-white hover:bg-gray-700"
      >
        Profile
      </button>
      <button
        onClick={() => {
          user ? signOut() : logoutBroker();
          setProfileMenuOpen(false);
        }}
        className="block px-4 py-2 text-white hover:bg-gray-700 cursor-pointer"
      >
        Logout
      </button>
    </div>
  );

  return (
    <nav className="bg-black ">
      <div className="px-4  md:px-6 xl:px-16 lg:px-10 relative z-10">
        <div className="flex h-16 items-center justify-between">
          <div  className={`flex items-center ml-2 justify-start w-20 sm:w-auto h-16 ${
              isMobileMenuOpen ? "bg-black bg-opacity-40" : ""
            }`}
          >
            <Link to="/">
              <img className="h-6 w-32" src={Logo} alt="Luxury & Me" />
            </Link>
          </div>
          {location.pathname === "/"  && ( <div className="md:ml-10 mr-12">
            <select
              className="p-1  bg-black bg-transparent text-white  text-sm font-semibold"
              value={selectedCity}
              onChange={(e) => handleCitySelect(e.target.value)}
            >
              {cities.map((city) => (
                <option key={city.id} value={city.city_Name} className="bg-black">
                  {city.city_Name}
                </option>
              ))}
            </select>
          </div>)}
         

          <div className="absolute inset-y-0 right-2 flex items-center sm:hidden">
          {(user || broker)?
           <div>            {(user || broker) && (
            <div className="flex items-center ml-6 relative">
              <div ref={profileMenuRef}>
                <button
                  onClick={handleProfileIconClick}
                  className="flex items-center focus:outline-none"
                >
                  <UserCircleIcon className="h-6 w-6 text-white mr-4" />
                </button>
                {isProfileMenuOpen && profileMenuContent}
              </div>
            </div>
          )}</div>: <button
           onClick={toggleMobileMenu}
           className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
         >
           {isMobileMenuOpen ? (
             <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
           ) : (
             <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
           )}
         </button>}
          </div>

          <div className="hidden sm:flex items-center justify-end flex-1">
            <div className="ml-6">
              <div className="flex space-x-2">
                {navigation
                  .filter((item) => item.show !== false)
                  .map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={`block px-3 py-2 rounded-md text-sm font-medium ${
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-white hover:bg-white hover:text-[#065279]"
                      }`}
                      onClick={() => handleNavLinkClick(item.to)}
                    >
                      {item.name}
                    </Link>
                  ))}
              </div>
            </div>
            {(user || broker) && (
              <div className="flex items-center ml-6 relative">
                <div ref={profileMenuRef}>
                  <button
                    onClick={handleProfileIconClick}
                    className="flex items-center focus:outline-none"
                  >
                    <UserCircleIcon className="h-6 w-6 text-white mr-4" />
                  </button>
                  {isProfileMenuOpen && profileMenuContent}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="sm:hidden">
          <div className=" pt-2 mb-16 space-y-1 z-30 absolute w-full bg-black bg-opacity-40">
            {navigation
              .filter((item) => item.show !== false)
              .map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  className={`block px-3 py-2 rounded-md text-base font-medium ${
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-white hover:bg-white hover:text-[#065279]"
                  }`}
                  onClick={() => {
                    handleNavLinkClick(item.to);
                    setMobileMenuOpen(false);
                  }}
                >
                  {item.name}
                </Link>
              ))}

                {(user || broker) && (
              <div className="flex items-center ml-6 relative">
                <div ref={profileMenuRef}>
                  <button
                    onClick={handleProfileIconClick}
                    className="flex items-center focus:outline-none"
                  >
                    <UserCircleIcon className="h-6 w-6 text-white mr-4" />
                  </button>
                  {isProfileMenuOpen && profileMenuContent}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* Render the BrokerView component */}
      <BrokerView
        isOpen={isBrokerViewOpen}
        onClose={() => setBrokerViewOpen(false)}
        broker={broker}
      />
    </nav>
  );
};

export default Navbar;
