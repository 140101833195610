// SearchDataContext.js
import React, { createContext, useState, useContext } from "react";

const SearchDataContext = createContext();

export const SearchDataProvider = ({ children }) => {
  const [data, setData] = useState(null);

  return (
    <SearchDataContext.Provider value={{ data, setData }}>
      {children}
    </SearchDataContext.Provider>
  );
};

export const useSearchData = () => {
  return useContext(SearchDataContext);
};
