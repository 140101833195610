import React, { useState, useEffect } from "react";
import PropertyCard from "./PropertyCard";
import Api from "../../api/Api";
import { useParams,Link ,useNavigate} from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
const LocationProperty = () => {
  const [properties, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { cityName, zone, City_name, Location, Zone, City_Name } = useParams();
const navigate=useNavigate()
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await Api.get("/adminproperties/getAllProperties");
        if (cityName) {
          const filtercity = response.data.allProperties.filter(
            (res) => res.cityProperties.city_Name === cityName
          );
          setProjects(filtercity);
          setLoading(false);
        } else if (zone) {
          const filtercity = response.data.allProperties.filter(
            (res) =>
              res.cityProperties.city_Name === City_name &&
              res.propertylocaation.pramotional_Location === zone
          );
          setProjects(filtercity);
          setLoading(false);
        } else if (Location) {
          const filtercity = response.data.allProperties.filter(
            (res) =>
              res.cityProperties.city_Name === City_Name &&
              res.propertylocaation.pramotional_Location === Zone &&
              res.propertylocaation.actual_Location === Location
          );
          setProjects(filtercity);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    };

    fetchProjects();
   
  }, []);
  const [cityList,setcityList]=useState([])
  useEffect(() => {
  const fetchCities = async () => {
    try {
      const response = await Api.get("/adminproperties/getCity");
      if(City_Name || City_name || cityName ) {
        const city=response?.data?.citylist?.filter((city)=>city.city_Name===cityName ||city.city_Name===City_name ||city.city_Name===City_Name  )
        setcityList(city)
      }
  
    } catch (error) {
      console.error("Error fetching projects:", error.message);
    }
  };

  fetchCities();
}, []);

  const handleBack=()=>{
    navigate(-1)
  }
  return (
    <div className="bg-gray-100   lg:mt-16 mt-24 md:mt-12 p-3 md:p-6">
        <div className="text-[#065279] flex flex-row py-1  lg:px-20 md:px-6">
          <nav className="text-sm " aria-label="Breadcrumb">
            <ol className="list-none inline-flex text-[#065279] text-[10px]">
              <li className="flex items-center">
                <Link to="/" className="text-[#065279] hover:underline">Home</Link>
                <MdOutlineKeyboardArrowRight className="mx-1" />
              </li>
              <li className="flex items-center">
                <Link onClick={handleBack} className="text-[#065279] hover:underline">Previous Page</Link>
                <MdOutlineKeyboardArrowRight className="mx-1" />
              </li>
    
            </ol>
          </nav>
        </div>
      <header className="mb-8 pl-1 md:pl-14">
        <h2 className="text-sm md:text-md lg:text-lg font-bold mb-4  ">
          {cityName
            ? cityName
            : zone
            ? `${City_name}/${zone}`
            : `${City_Name}/${Zone}/${Location}`}
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-5 md:grid-cols-3 lg:gap-16 md:gap-6 ">
        <p className="text-justify text-gray-600 lg:col-span-3 md:col-span-2 mt-2 order-2 md:order-1 text-[12px] md:text-sm">
          {/* Nestled in the eastern part of Bangalore, Whitefield is a vibrant
          suburb that seamlessly blends modernity with tradition. Known for its
          IT hubs, upscale residential complexes, and bustling commercial
          spaces, Whitefield has transformed into one of the most sought-after
          neighborhoods in the city.<br/> Bangalore has one of the country's most active residential real estate markets. Knight Frank, one of the world's leading real estate consultancy firms, recently released a report naming Bangalore, in the Indian state of Karnataka, as the best city to invest in residential real estate in 2021. 
           */}
           {cityList[0]?.description}
          
           </p>
        {/* <img alt="gallery" className="w-full h-72 lg:col-span-2 md:col-span-1 order-1 md:order-2  rounded-md" src={"https://img.freepik.com/free-photo/aerial-view-suzhou-overpass_1359-523.jpg?w=2000&t=st=1720680796~exp=1720681396~hmac=08b642ab5845020f80f3c2806e45f0df87b426807e1255acb7226926d22a58f3"} /> */}
        <img alt="gallery" className="w-full h-72 lg:col-span-2 md:col-span-1 order-1 md:order-2  rounded-md" src={cityList[0]?.city_URL} />
      
        </div>
       
      </header>

      <section className="pl-1 md:pl-12">
        <h2 className="text-sm md:text-md lg:text-lg font-semibold  text-[#CC163A] ">New Launches</h2>
        {loading ? (
          <div className="text-[12px] md:text-sm py-8">New Launches Properties loading ...</div>
        ) : properties.filter((res) => res.Tags === "New Launches").length ===
          0 ? (
          <div className="text-[12px] md:text-sm py-8">No Trending Properties found.</div>
        ) : (
          <div className="grid grid-flow-col auto-cols-max mt-2  overflow-x-auto gap-2 ">
            {properties
              .filter((res) => res.Tags === "New Launches")
              .map((property, index) => (
                <PropertyCard key={index} property={property} />
              ))}
          </div>
        )}
      </section>

      <section className="mt-6 pl-1  md:pl-12">
        <h2 className="text-sm md:text-md lg:text-lg font-semibold  text-[#CC163A]">
          Top Properties
        </h2>

        {loading ? (
          <div className="text-[12px] md:text-sm py-8">Top Properties loading ...</div>
        ) : properties.filter((res) => res.Tags === `Top Properties`).length ===
          0 ? (
          <div className="text-[12px] md:text-sm py-8">No Top Properties found.</div>
        ) : (
          <div className="grid grid-flow-col auto-cols-max mt-2 overflow-x-auto gap-2">
            {properties
              .filter((res) => res.Tags === `Top Properties`)
              .map((property, index) => (
                <PropertyCard key={index} property={property} />
              ))}
          </div>
        )}
      </section>
      <section className="mt-6 pl-1  md:pl-12">
        <h2 className="text-sm md:text-md lg:text-lg font-semibold  text-[#CC163A]">
          Featured Properties
        </h2>

        {loading ? (
          <div className="text-[12px] md:text-sm py-8">Featured Properties loading ...</div>
        ) : properties.filter((res) => res.Tags === `Featured Properties`)
            .length === 0 ? (
          <div className="text-[12px] md:text-sm py-8">No Featured Properties found.</div>
        ) : (
          <div className="grid grid-flow-col auto-cols-max mt-2  overflow-x-auto gap-2 ">
            {properties
              .filter((res) => res.Tags === `Featured Properties`)
              .map((property, index) => (
                <PropertyCard key={index} property={property} />
              ))}
          </div>
        )}
      </section>

      <section className="mt-6 pl-1 md:pl-12">
        <h2 className="text-sm md:text-md lg:text-lg font-semibold  text-[#CC163A]">
          Upcoming Launches
        </h2>
        {loading ? (
          <div className="text-[12px] md:text-sm py-8">Upcoming Launches Properties loading ...</div>
        ) : properties.filter((res) => res.Tags === "Upcoming Launches")
            .length === 0 ? (
          <div className="text-[12px] md:text-sm py-8">No Upcoming Properties found.</div>
        ) : (
          <div className="grid grid-flow-col auto-cols-max mt-2  overflow-x-auto gap-2 ">
            {properties
              .filter((res) => res.Tags === "Upcoming Launches")
              .map((property, index) => (
                <PropertyCard key={index} property={property} />
              ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default LocationProperty;
