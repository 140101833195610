import React from 'react';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-5VMZ349N'
}

const GTMProvider = ({ children }) => {
  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, []);

  return <>{children}</>;
};

export default GTMProvider;
