// src/authHelper.js

export const isLoggedIn = () => {
    return !!sessionStorage.getItem('authToken');
  };
  
  export const getUserData = () => {
    const userData = sessionStorage.getItem('userData');
    return userData ? JSON.parse(userData) : null;
  };
  