import axios from 'axios';
import { isLoggedIn, getUserData } from './authHelper';
import Api from '../api/Api';

// Function to extract and store the landed value
const storeLandedValue = () => {
  const url = window.location.href;
  const landed = url.substring(url.lastIndexOf('/') + 1);
  sessionStorage.setItem('landed', landed);
};

// Call the function to store the landed value when the script is first run
storeLandedValue();

// Initialize an empty set to store unique page URLs
const visitedPages = new Set(JSON.parse(sessionStorage.getItem('visitedPages')) || []);
let lastPage = null;
let totalTimeSpent = 0; // Initialize total time spent on the site
const generateRandomString = (length) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

const generateUserId = () => {
  const totalUsers = parseInt(sessionStorage.getItem('totalUsers') || '0', 10) + 1;
  sessionStorage.setItem('totalUsers', totalUsers.toString());
  const randomString = generateRandomString(6);
  return `${totalUsers}-${randomString}`;
};

export const trackUser = () => {
  let userId = sessionStorage.getItem('userId');

  if (!userId && !isLoggedIn() && !sessionStorage.getItem('broker')) {
    userId = generateUserId();
    sessionStorage.setItem('userId', userId);
    sessionStorage.setItem('loginType', 'guest');
  } else if (isLoggedIn()) {
    const userData = getUserData();
    userId = userData.userId;
    sessionStorage.setItem('userId', userId);
    sessionStorage.setItem('loginType', 'user');
  } else if (sessionStorage.getItem('broker')) {
    const brokerData = JSON.parse(sessionStorage.getItem('broker'));
    userId = brokerData.userId; // Assuming broker data contains userId
    sessionStorage.setItem('userId', userId);
    sessionStorage.setItem('loginType', 'broker');
  }

  return userId;
};

export const saveTrackingData = (event, data) => {
  const trackingData = JSON.parse(sessionStorage.getItem('trackingData')) || [];
  const user = isLoggedIn() ? getUserData() : { userType: 'newUser' };

  const newEntry = {
    event,
    data,
    url: window.location.href,
    page: document.title,
    user,
    timestamp: new Date().toISOString()
  };

  // Store unique page URLs
  const url = window.location.href;

  // Find the position of the last slash
  const lastSlashIndex = url.lastIndexOf('/');
  
  // Find the position of the second-to-last slash
  const secondLastSlashIndex = url.lastIndexOf('/', lastSlashIndex - 1);
  
  // Extract the substring from the second-to-last slash to the end
  const lastTwoParts = url.substring(secondLastSlashIndex);
  
  visitedPages.add(lastTwoParts);
  sessionStorage.setItem('visitedPages', JSON.stringify(Array.from(visitedPages))); // Store visited pages in session storage
  
  if (event === 'Scroll') {
    const filteredData = trackingData.filter(item => item.event !== 'Scroll');
    filteredData.push(newEntry);
    sessionStorage.setItem('trackingData', JSON.stringify(filteredData));
  } else {
    trackingData.push(newEntry);
    sessionStorage.setItem('trackingData', JSON.stringify(trackingData));
  }
};

export const getTrackingData = () => {
  return JSON.parse(sessionStorage.getItem('trackingData')) || [];
};

export const clearTrackingData = () => {
  sessionStorage.removeItem('trackingData');
  sessionStorage.removeItem('visitedPages'); // Clear visited pages
};

export const startTimer = () => {
  const startTime = Date.now();
  sessionStorage.setItem('startTime', startTime);
  sessionStorage.setItem('startPage', window.location.href);
};
export const getMostVisitedPages = () => {
  const trackingData = JSON.parse(sessionStorage.getItem('trackingData')) || [];
  const pageCounts = {};

  // Count the occurrences of each page in the trackingData
  trackingData.forEach(entry => {
    const pageUrl = entry.url.substring(entry.url.lastIndexOf('/') + 1);
    pageCounts[pageUrl] = (pageCounts[pageUrl] || 0) + 1;
  });

  // Sort the pages by visit count in descending order
  const sortedPages = Object.keys(pageCounts).sort((a, b) => pageCounts[b] - pageCounts[a]);

  // Return the sorted pages with their visit counts
  return sortedPages.map(page => ({ page, count: pageCounts[page] }));
};

export const stopTimer = () => {
  const startTime = parseInt(sessionStorage.getItem('startTime'), 10);
  const startPage = sessionStorage.getItem('startPage');
  if (startTime && startPage) {
    const endTime = Date.now();
    const timeSpent = endTime - startTime;
    totalTimeSpent += timeSpent; // Accumulate total time spent
    const totalTimeSpentMinutes = (totalTimeSpent / 60000).toFixed(2); // Convert total time to minutes and round to two decimal places
    sessionStorage.setItem('totalTimeSpent', totalTimeSpentMinutes); // Store total time spent in session storage as minutes
    const formattedStartTime = new Date(startTime).toLocaleString(); // Formatting startTime
    const formattedEndTime = new Date(endTime).toLocaleString(); // Formatting endTime
    const trackingData = JSON.parse(sessionStorage.getItem('trackingData')) || [];
    const user = isLoggedIn() ? getUserData() : { userType: 'newUser' };

    const newEntry = {
      event: 'TimeSpent',
      data: { timeSpent: (timeSpent / 60000).toFixed(2) }, // Convert time spent to minutes and round to two decimal places
      url: startPage,
      page: document.title,
      user,
      startTime: formattedStartTime, // Adding formatted startTime to the entry
      endTime: formattedEndTime, // Adding formatted endTime to the entry
      timestamp: new Date().toISOString()
    };

    trackingData.push(newEntry);
    sessionStorage.setItem('trackingData', JSON.stringify(trackingData));
  }
  sessionStorage.removeItem('startTime');
  sessionStorage.removeItem('startPage');
};

// Function to send all session storage data to the API
const sendSessionDataToAPI = async () => {
  try {
    const sessionData = {
      sessionActive: sessionStorage.getItem('sessionActive-1') === 'true',
      userId: sessionStorage.getItem('userId'),
      trackingData: JSON.parse(sessionStorage.getItem('trackingData')) || [],
      trafficSource: sessionStorage.getItem('trafficSource'),
      activeUsersCount: sessionStorage.getItem('activeUsersCount'),
      totalUsers: sessionStorage.getItem('totalUsers'),
      landed: sessionStorage.getItem('landed'),  // Retrieve the landed value from sessionStorage
      visitedPages: JSON.parse(sessionStorage.getItem('visitedPages')) || [], // Retrieve visited pages from session storage
      pageCount: visitedPages.size, // Count of unique pages visited
      lastPage, // Add the last page user visited
      totalTimeSpent: sessionStorage.getItem('totalTimeSpent'), // Include total time spent in minutes
     
    };

    const response = await Api.post('/usersrouts/createOrUpdateTracker', sessionData);
    console.log('Data successfully sent to the API', response.data);

    // Optionally, clear the tracking data after successfully sending it
    clearTrackingData();
  } catch (error) {
    console.error('Error sending data to the API', error);
  }
};

// Function to track time spent on page
const trackTimeSpentOnPage = () => {
  // Start timer when page is loaded
  window.addEventListener('load', startTimer);

  // Stop timer and send data when page is unloaded or user closes the tab/window
  window.addEventListener('beforeunload', stopTimer);
  window.addEventListener('unload', async () => {
    await sendSessionDataToAPI(); // Send data to API when the user closes the website
  });
};

const trackLinkClicks = () => {
  document.addEventListener('click', (event) => {
    if (event.target.tagName === 'A') {
      const linkUrl = event.target.href;
      const propertyId = linkUrl.substring(linkUrl.lastIndexOf('/') + 1); // Extract property ID from the URL
      if (linkUrl.includes('/properties/') && propertyId) { // Check if the link is for a property
        visitedPages.add(propertyId);
        sessionStorage.setItem('lastClickedPropertyId', propertyId); // Store the clicked property ID in session storage
        saveTrackingData('PropertyClick', { propertyId }); // Track property click
        lastPage = linkUrl;

        // Extract property name from the link text
        const propertyName = event.target.innerText.trim(); // Assuming the property name is directly visible in the link text
        
        // Store property ID and name in session storage
        sessionStorage.setItem('lastClickedPropertyName', propertyName);

        // Store in trackingData
        saveTrackingData('LinkClick', { linkUrl, event: 'PropertyClick', propertyId, propertyName });
      } else {
        visitedPages.add(linkUrl.substring(linkUrl.lastIndexOf('/') + 3));
        saveTrackingData('PageVisit', { linkUrl });
        lastPage = linkUrl;



        // Extract property ID and name if available in the link text
        const linkText = event.target.innerText.trim();
        const propertyIdFromText = linkText.match(/\d+/); // Extract digits (assuming property ID is numerical)
        const propertyNameFromText = linkText.replace(/\d+/g, '').trim(); // Remove digits from text

        // Store property ID and name if found in link text
        if (propertyIdFromText && propertyIdFromText.length > 0) {
          sessionStorage.setItem('lastClickedPropertyId', propertyIdFromText[2]);
        }
        if (propertyNameFromText) {
          sessionStorage.setItem('lastClickedPropertyName', propertyNameFromText);
        }

        // Store in trackingData
        saveTrackingData('LinkClick', { linkUrl, event: 'PageVisit', propertyId: propertyIdFromText, propertyName: propertyNameFromText });
      }
    } else {
      // For other types of link clicks, such as navigation buttons, you can log them here
      const element = event.target;
      const elementText = element.innerText;
      const elementType = element.tagName;
      // Customize the event type and data as needed
      saveTrackingData('OtherLinkClick', { elementText, elementType });
    }
  });
};

// Function to get visited pages data
export const getVisitedPages = () => {
  return JSON.parse(sessionStorage.getItem('visitedPages')) || []; // Retrieve and return visited pages from session storage
};

// Start tracking time spent on page and link clicks
trackTimeSpentOnPage();
trackLinkClicks();
