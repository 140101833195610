import React, { useState, useEffect, useRef } from "react";
import Api from "../../../api/Api";
import logo from "../../../Assets/Group (1).png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiBuildings } from "react-icons/bi";
import { TbLocation } from "react-icons/tb";
import { MdOutlineAssuredWorkload } from "react-icons/md";
import { IoMdPerson } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../authentication/AuthContext";
import TagManager from "react-gtm-module";
import BrokerView from "../../../Broker/BrokerView";


import {
  Bars3Icon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
const tagManagerArgs = {
  gtmId: "GTM-5VMZ349N",
};

TagManager.initialize(tagManagerArgs);

const Navbar = ({ onCitySelect, cities, selectedCity }) => {
  const [cityList, setCityList] = useState([]);
  const { user, signOut, broker, logoutBroker } = useAuth();
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isBrokerViewOpen, setBrokerViewOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const location = useLocation();
  const navigate = useNavigate();
  const locatione = useLocation();

  const handleProfileIconClick = () => {
    if (broker) {
      setBrokerViewOpen(true);
    } else {
      setProfileMenuOpen(!isProfileMenuOpen);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      setProfileMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavLinkClick = (to) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        linkUrl: to,
      },
    });
    navigate(to);
  };

  useEffect(() => {
    const handleScroll = () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "scrollEvent",
        scrollDepth: window.scrollY,
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigation = [
    {
      name: "Broker Login",
      to: "/broker/login",
      current: locatione.pathname === "/broker/login",
      show: !(user || broker),
    },
    {
      name: "Signin",
      to: "/login",
      current: locatione.pathname === "/login",
      show: !(user || broker),
    },
  ];
  const searchId = generateUUID();
  const [location, setLocation] = useState("Select Location");
  const [selectedOption, setSelectedOption] = useState("BUY");
  const [userInput, setUserInput] = useState("");

  const [locations, setLocations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [builder, setBuilder] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const userItem = localStorage.getItem("user");
  const users = userItem ? JSON.parse(userItem).id : null;
  const handleCitySelect = async (city) => {
    onCitySelect(city);
  };
  useEffect(() => {
    setLocation(userInput);
  }, [userInput]);

  function generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const handleOptionClick = async (option) => {
    setSelectedOption(option);

    if (option === "SEARCH") {
      try {
        const response = await Api.post(
          `/searchRoutes/searchProperties?searchQuery=${location}&userId=${users}`
        );
        const searchData = response.data.properties;

        sessionStorage.setItem(searchId, JSON.stringify(searchData));
        navigate(
          `/PropertydetailPage?location=${encodeURIComponent(
            userInput
          )}&searchId=${encodeURIComponent(searchId)}`
        );
      } catch (error) {
        console.error("Error performing search:", error.message);
        alert("Error performing search. Please try again.");
      }
    } else {
      if (selectedCity || userInput) {
        try {
          let searchQuery;

          if (selectedCity) {
            searchQuery = selectedCity;
          } else {
            searchQuery = location;
          }

          const response = await Api.post(
            `/searchRoutes/searchProperties?searchQuery=${searchQuery}&userId=${users}`
          );
          const searchData = response.data.properties;
          sessionStorage.setItem(searchId, JSON.stringify(searchData));
          navigate(
            `/PropertydetailPage?location=${encodeURIComponent(
              selectedLocation ? selectedLocation.userInput : userInput
            )}&searchId=${encodeURIComponent(searchId)}`
          );
        } catch (error) {
          console.error("Error performing search:", error.message);
          alert("Error performing search. Please try again.");
        }
      } else {
        toast.warn("Please select a location before searching.");
      }
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await Api.get("/adminproperties/getLocation");
        setLocations(response.data.locationlist);
      } catch (error) {
        console.error("Error fetching locations:", error.message);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await Api.get("/adminproperties/getAllProperties");
        setProjects(response.data.allProperties);
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    };

    fetchProjects();
    const fetchBuilder = async () => {
      try {
        const response = await Api.get("/uiDataRoutes/getAllPublished");
        setBuilder(response.data.checkPendings);
      } catch (error) {
        console.error("Error fetching builders:", error.message);
      }
    };

    fetchBuilder();
  }, []);

  useEffect(() => {
    Api.get("/adminproperties/getCity")
      .then((response) => {
        setCityList(response.data.citylist);
      })
      .catch((error) => console.error("Error fetching city list:", error));
  }, []);

  const filteredLocations = locations.filter((loc) =>
    loc.actual_Location.toLowerCase().includes(userInput.toLowerCase())
  );

  const handleLocationChange = (event) => {
    setUserInput(event.target.value);
    setSelectedLocation(null);
  };

  const handleLocation = async (clickedLocation) => {
    setUserInput("");
    setLocation(clickedLocation.actual_Location);
    setSelectedLocation(clickedLocation);
    try {
      const response = await Api.post(
        `/searchRoutes/searchProperties?searchQuery=${clickedLocation.actual_Location}&userId=${users}`
      );
      const searchData = response.data.properties;
      sessionStorage.setItem(searchId, JSON.stringify(searchData));
      navigate(
        `/PropertydetailPage?location=${encodeURIComponent(
          clickedLocation.actual_Location
        )}&searchId=${encodeURIComponent(searchId)}`
      );
    } catch (error) {
      console.error("Error performing search:", error.message);
      alert("Error performing search. Please try again.");
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleOptionClick("SEARCH");
    }
  };

  const handleProjectClick = async (project) => {
    setUserInput("");
    try {
      const response = await Api.post(
        `/searchRoutes/searchProperties?searchQuery=${project.project_Name}&userId=${users}`
      );
      const searchData = response.data.properties;
      sessionStorage.setItem(searchId, JSON.stringify(searchData));
      navigate(
        `/PropertydetailPage?location=${encodeURIComponent(
          project.project_Name
        )}&searchId=${encodeURIComponent(searchId)}`
      );
    } catch (error) {
      console.error("Error performing search:", error.message);
      alert("Error performing search. Please try again.");
    }
  };

  const handleBuilderClick = async (builder) => {
    setUserInput("");

    try {
      const response = await Api.post(
        `/searchRoutes/searchProperties?searchQuery=${builder.builder_name}&userId=${users}`
      );
      const searchData = response.data.properties;
      sessionStorage.setItem(searchId, JSON.stringify(searchData));

      navigate(
        `/PropertydetailPage?location=${encodeURIComponent(
          builder.builder_name
        )}&searchId=${encodeURIComponent(searchId)}`
      );
    } catch (error) {
      console.error("Error performing search:", error.message);
      alert("Error performing search. Please try again.");
    }
  };

  const profileMenuContent = (
    <div className="absolute right-0 mt-2 bg-black border rounded-md py-2 w-48 z-20">
      <button
        onClick={() => {
          handleNavLinkClick(user ? "/profile" : "/BrokerProfile");
          setProfileMenuOpen(false);
        }}
        className="block px-4 py-2 text-white hover:bg-gray-700"
      >
        Profile
      </button>
      <button
        onClick={() => {
          user ? signOut() : logoutBroker();
          setProfileMenuOpen(false);
        }}
        className="block px-4 py-2 text-white hover:bg-gray-700 cursor-pointer"
      >
        Logout
      </button>
    </div>
  );

  return (
    <nav
      className="grid grid-cols-5 md:grid-cols-10 lg:grid-cols-7  xl:grid-cols-7 bg-white px-2 items-center  lg:px-10 xl:px-20 fixed top-0 z-40 w-full"
      style={{ boxShadow: "0px 0px 6px 0px gray" }}
    >
      <div className="grid items-center  justify-center md:justify-start lg:justify-between col-span-2 md:col-span-1 lg:col-span-1">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="col-span-2 md:col-span-2 lg:col-span-1 grid justify-center  ">
        <select
          className="rounded-lg p-1 md:p-2  border-xl border-2   bg-white text-[#065279]  text-sm font-semibold"
          value={selectedCity}
          onChange={(e) => handleCitySelect(e.target.value)}
        >
          {cities.map((city) => (
            <option key={city.id} value={city.city_Name}>
              {city.city_Name}
            </option>
          ))}
        </select>
      </div>

      <div className="hidden md:block  col-span-1 md:col-span-5  lg:col-span-3 m-4">
        <div
          className="w-full p-3 flex items-center flex-wrap space-x-4 space-x-reverse rounded-2xl border-2 bg-[#FFFFFF] bg-opacity-100 text-black" >
          <div className="flex-1 w-full  ">
            <div className="relative w-full ">
              <input
                className="w-full border-0 text-black text-xs lg:text-base focus:outline-none font-medium "
                style={{ fontFamily: "Arial" }}
                placeholder="Search Project, Location, Builder..."
                type="text"
                value={location}
                onChange={handleLocationChange}
                onKeyPress={handleEnterKeyPress}
              />
              <div className="absolute -right-3 top-0 -mt-3 p-1">
                <button
                  className="p-2 text-white bg-[#065279] rounded-xl flex justify-center items-center px-7"
                  onClick={() => handleOptionClick("SEARCH")}
                >
                  Search
                </button>
              </div>
            </div>
            {userInput.length > 0 && (
              <ul
                className="absolute lg:left-[31rem] md:left-[16rem] md:w-[47%] lg:w-[41%] bg-white border border-gray-300 rounded-b-md mt-5 max-h-40 overflow-y-auto z-10"
                style={{ fontFamily: "Arial" }}
              >
                {projects
                  .filter(
                    (project) =>
                      project.project_Name
                        .toLowerCase()
                        .includes(userInput.toLowerCase()) &&
                      (!selectedCity ||
                        project?.cityProperties.city_Name === selectedCity)
                  )
                  .map((project) => (
                    <li
                      key={project.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleProjectClick(project)}
                    >
                      <div className="w-full flex flex-row">
                        <div className="w-5/6 text-base font-extralight">
                          {project.project_Name}
                        </div>
                        <div className="w-1/6 text-base font-extralight grid grid-flow-col justify-end items-center">
                          Project <BiBuildings className="ml-1" />
                        </div>
                      </div>
                    </li>
                  ))}
                {builder
                  .filter((builder) =>
                    builder.builder_name
                      .toLowerCase()
                      .includes(userInput.toLowerCase())
                  )
                  .map((builder) => (
                    <li
                      key={builder.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleBuilderClick(builder)}
                    >
                      <div className="w-full flex flex-row">
                        <div className="w-5/6 text-base font-extralight">
                          {builder.builder_name}
                        </div>
                        <div className="w-1/6 text-base font-extralight grid grid-flow-col justify-end items-center">
                          Builder <MdOutlineAssuredWorkload className="ml-1" />
                        </div>
                      </div>
                    </li>
                  ))}
                {locations
                  .filter(
                    (loc) =>
                      loc.actual_Location
                        .toLowerCase()
                        .includes(userInput.toLowerCase()) &&
                      (!selectedCity ||
                        loc?.citylocaation?.city_Name === selectedCity)
                  )
                  .map((loc) => (
                    <li
                      key={loc.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleLocation(loc)}
                    >
                      <div className="w-full flex flex-row">
                        <div className="w-5/6 text-base font-extralight">
                          {loc.actual_Location}
                        </div>
                        <div className="w-1/6 text-base font-extralight grid grid-flow-col justify-end items-center">
                          Location <TbLocation className="ml-1" />
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className=" grid col-span-1 md:col-span-2 justify-end items-center py-2 lg:col-span-2">
      <nav className="">
        <div className="flex h-16 items-center justify-between">
         

          <div className=" inset-y-0 right-2 flex items-center sm:hidden">
          {(user || broker)?
           <div>            {(user || broker) && (
            <div className="flex items-center ml-6 relative">
              <div ref={profileMenuRef}>
                <button
                  onClick={handleProfileIconClick}
                  className="flex items-center focus:outline-none"
                >
                  <UserCircleIcon className="h-6 w-6 text-[#065279] mr-4" />
                </button>
                {isProfileMenuOpen && profileMenuContent}
              </div>
            </div>
          )}</div>: <button
           onClick={toggleMobileMenu}
           className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
         >
           {isMobileMenuOpen ? (
             <XMarkIcon className=" text-[#065279] block h-6 w-6" aria-hidden="true" />
           ) : (
             <Bars3Icon className="text-[#065279] block h-6 w-6" aria-hidden="true" />
           )}
         </button>}
          </div>
{/* tab view */}
          <div className="hidden sm:flex items-center justify-end flex-1">
            <div className="">
              <div className="flex space-x-2">
                {navigation
                  .filter((item) => item.show !== false)
                  .map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={`block px-2 py-2 rounded-md text-sm font-medium text-[#065279] `}
                        
                      onClick={() => handleNavLinkClick(item.to)}
                    >
                      {item.name}
                    </Link>
                  ))}
              </div>
            </div>
            {(user || broker) && (
              <div className="flex items-center ml-6 relative">
                <div ref={profileMenuRef}>
                  <button
                    onClick={handleProfileIconClick}
                    className="flex items-center focus:outline-none"
                  >
                    <UserCircleIcon className="h-6 w-6 text-[#065279] mr-4" />
                  </button>
                  {isProfileMenuOpen && profileMenuContent}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* //sm hidden */}
      {isMobileMenuOpen && (
        <div className="">
          <div className=" pt-2 mb-16 space-y-1 z-30 absolute left-0 w-full bg-black bg-opacity-100">
            {navigation
              .filter((item) => item.show !== false)
              .map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  className={`block px-3 py-2 rounded-md text-base text-white font-medium `}
                  onClick={() => {
                    handleNavLinkClick(item.to);
                    setMobileMenuOpen(false);
                  }}
                >
                  {item.name}
                </Link>
              ))}

                {(user || broker) && (
             <div className="" ref={profileMenuRef}>
             <button
               onClick={handleProfileIconClick}
               className="focus:outline-none bg-[#065279]"
             >
               <UserCircleIcon className="h-6 w-6 text-blue-500 mr-4" />
               
             </button>
             {isProfileMenuOpen && profileMenuContent}
           </div>
            )}
          </div>
        </div>
      )}
      {/* Render the BrokerView component */}
      <BrokerView
        isOpen={isBrokerViewOpen}
        onClose={() => setBrokerViewOpen(false)}
        broker={broker}
      />
    </nav>
      </div>
      <div className="md:hidden block  col-span-5 py-2 ">
      <div
          className="w-full p-3 flex items-center flex-wrap space-x-4 space-x-reverse rounded-2xl border-2 bg-[#FFFFFF] bg-opacity-100 text-black" >
          <div className="flex-1 w-full  ">
            <div className="relative w-full ">
              <input
                className="w-full border-0 text-black text-sm focus:outline-none font-medium "
                style={{ fontFamily: "Arial" }}
                placeholder="Search Project, Location, Builder..."
                type="text"
                value={location}
                onChange={handleLocationChange}
                onKeyPress={handleEnterKeyPress}
              />
              <div className="absolute -right-3 top-0 -mt-3 p-1">
                <button
                  className="p-3 text-white bg-[#065279] rounded-xl flex justify-center items-center px-4"
                  onClick={() => handleOptionClick("SEARCH")}
                >
                 <FaSearch/>
                </button>
              </div>
            </div>
            {userInput.length > 0 && (
              <ul
                className="absolute left-4 w-[90%]  bg-white border border-gray-300 rounded-b-md mt-4 max-h-40 overflow-y-auto z-10"
                style={{ fontFamily: "Arial" }}
              >
                {projects
                  .filter(
                    (project) =>
                      project.project_Name
                        .toLowerCase()
                        .includes(userInput.toLowerCase()) &&
                      (!selectedCity ||
                        project?.cityProperties.city_Name === selectedCity)
                  )
                  .map((project) => (
                    <li
                      key={project.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleProjectClick(project)}
                    >
                      <div className="w-full flex flex-row">
                        <div className="w-5/6 text-sm font-medium">
                          {project.project_Name}
                        </div>
                        <div className="w-1/6 text-sm font-medium grid grid-flow-col justify-end items-center">
                          Project <BiBuildings className="ml-1" />
                        </div>
                      </div>
                    </li>
                  ))}
                {builder
                  .filter((builder) =>
                    builder.builder_name
                      .toLowerCase()
                      .includes(userInput.toLowerCase())
                  )
                  .map((builder) => (
                    <li
                      key={builder.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleBuilderClick(builder)}
                    >
                      <div className="w-full flex flex-row">
                        <div className="w-5/6 text-sm font-medium">
                          {builder.builder_name}
                        </div>
                        <div className="w-1/6 text-sm font-medium grid grid-flow-col justify-end items-center">
                          Builder <MdOutlineAssuredWorkload className="ml-1" />
                        </div>
                      </div>
                    </li>
                  ))}
                {locations
                  .filter(
                    (loc) =>
                      loc.actual_Location
                        .toLowerCase()
                        .includes(userInput.toLowerCase()) &&
                      (!selectedCity ||
                        loc?.citylocaation?.city_Name === selectedCity)
                  )
                  .map((loc) => (
                    <li
                      key={loc.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleLocation(loc)}
                    >
                      <div className="w-full flex flex-row">
                        <div className="w-5/6 text-sm font-extralight">
                          {loc.actual_Location}
                        </div>
                        <div className="w-1/6 text-sm font-medium grid grid-flow-col justify-end items-center">
                          Location <TbLocation className="ml-1" />
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
