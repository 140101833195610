import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
  let location = useLocation();
  const { user,broker } = useAuth();

  return (
    <>
      {user === null  && broker=== null? (
        <Navigate to="/broker/login" state={{ from: location }} replace />
      ) : (
        children
      )}
    </>
  );
};

export default ProtectedRoute;
