import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../Assets/Luxury&me-logo.png";
import { FaSquareXTwitter, FaYoutube, FaInstagram } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div className=" py-8 px-4 md:px-10 lg:px-16" style={{ backgroundColor: "#002349" }}>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-6 space-y-2 space-x-2 gap-6">
          <div className=" md:col-span-2 lg:col-span-3">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-1 ml-2 ">
            <div className="flex md:justify-start   md:items-start lg:justify-start mt-6  text-xl font-sm md:col-span-1 ">
              <Link to="/">
                <img src={logo} alt="Luxury&Me Logo" className="w-48 h-26" />
              </Link>
            </div>
            <div className="mt-4  text-sm text-justify font-medium  line-clamp-5 text-white md:col-span-2">
              Looking to sell your home? Our expert team provides you with comprehensive support from start to finish. We ensure your property gets the best market exposure and you achieve the highest possible value. With personalized service and strategic marketing, selling your home has never been easier.
            </div>
            </div>
          </div>
          <div className="hidden lg:block"></div>
          <div className="grid grid-cols-2 md:grid-cols-2 md:col-span-2 lg:col-span-2 ">
            {[
              {
                title: "COMPANY",
                links: ["Blogs","Sales Enquiry","About Us",],
                urls: ["/blog","/", "/About"],
              },
              {
                title: "RESOURCES",
                links: ["Contact Us","Privacy Policy","Terms And Conditions",],
                urls: ["/Contact-Us", "/", "/"],
              },
            ].map((section, index) => (
              <div key={index} >
                <ul className="grid md:justify-center ">
                  <li
                    className={`mb-6 text-sm font-medium ${
                      index === 0 ? "text-917818" : "text-gray-800"
                    }`}
                    style={{ color: "#DDA556" }}
                  >
                    {section.title}
                  </li>
                  {section.links.map((link, idx) => (
                    <li
                      key={idx}
                      className="mb-5 text-sm font-medium text-white hover:text-[#917818] hover:text-md"
                    >
                      <Link to={section.urls[idx]}>{link}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center border-t border-solid border-white border-opacity-25 py-3">
  <p className="text-sm font-medium text-white mb-3 md:mb-0">
    &copy; Copyright {currentYear} Foyer Nest
  </p>
  <ul className="flex space-x-4 md:space-x-6">
    <li>
      <Link
        to="/"
        target="_blank"
        className="text-white text-md hover:text-blue-500 flex items-center"
      >
        <FaFacebookSquare className="mr-1 w-6 h-6" />
      </Link>
    </li>
    <li>
      <Link
        to="/"
        target="_blank"
        className="text-white text-md hover:text-blue-500 flex items-center"
      >
        <FaInstagram className="mr-1 w-6 h-6" />
      </Link>
    </li>
    <li>
      <Link
        to="/"
        target="_blank"
        className="text-white text-md hover:text-blue-500 flex items-center"
      >
        <FaSquareXTwitter className="mr-1 w-6 h-6" />
      </Link>
    </li>
    <li>
      <Link
        to="/"
        target="_blank"
        className="text-white text-md hover:text-blue-500 flex items-center"
      >
        <FaYoutube className="mr-1 w-6 h-6" />
      </Link>
    </li>
  </ul>
</div>

      </div>
    </>
  );
};
export default Footer;
