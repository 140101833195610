import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { FaBuilding, FaChevronDown } from 'react-icons/fa';
import { IoPersonSharp, IoChatbubbles } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useAuth } from "../authentication/AuthContext";
import ChangePasswordModal from './ChangePasswordModal'; 

const BrokerView = ({ isOpen, onClose , broker}) => {
  const { logoutBroker } = useAuth(); 
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false); 

  const userDataString = sessionStorage.getItem("broker");
  const userData = JSON.parse(userDataString);

  const handleDropdownToggle = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const handleLogout = () => {
    logoutBroker();
    onClose(); 
  };

  const handleLinkClick = () => {
    onClose(); 
  };

  const handleChangePasswordClick = () => {
    setModalOpen(true); 
  };

  return (
    <div
      className={`fixed inset-0 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-50`}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="absolute right-0 w-80 bg-white h-full shadow-xl">
        <div className="flex items-end p-4">
          <button onClick={onClose}>
            <XMarkIcon className="h-6 w-6 text-gray-800 right" />
          </button>
        </div>
        <div className="p-4">
          <div className="flex flex-col items-center justify-center mb-2">
            <img
              src={userData?.brokerLogo} 
              alt="Broker Profile"
              className="w-24 h-24 rounded-full mb-2 shadow-md"
            />
            <h2 className="text-lg font-semibold">{userData?.username}</h2> 
            <p className="text-sm text-gray-500">{userData?.role}</p> 
          </div>

          <div className="py-4 overflow-y-auto">
            <ul className="space-y-2 font-medium">
              <li>
                <Link
                  to="/BrokerProfile" 
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 group"
                  onClick={handleLinkClick} 
                >
                  <IoPersonSharp className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" />
                  <span className="ms-3">Profile</span>
                </Link>
              </li>
              <li>
                <button
                  type="button"
                  className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  onClick={handleDropdownToggle}
                >
                  <FaBuilding className="w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Upload Properties</span>
                  <FaChevronDown className="w-3 h-3 text-gray-500 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                </button>
                <ul className={`py-2 space-y-2 ${isDropdownOpen ? 'block' : 'hidden'}`}>
                  <li>
                    <Link
                      to="/rental-properties" 
                      className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={handleLinkClick} 
                    >
                      Rental Properties
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sale-properties" 
                      className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={handleLinkClick} 
                    >
                      Sale Properties
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/resale-properties" 
                      className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={handleLinkClick} 
                    >
                      Re-sale Properties
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/Chat" 
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 group"
                  onClick={handleLinkClick} 
                >
                  <IoChatbubbles className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" />
                  <span className="flex-1 ms-3 whitespace-nowrap">Chats</span>
                  <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>
                </Link>
              </li>
              <li>
                <button
                  onClick={handleChangePasswordClick} 
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 group"
                >
                  <FaBuilding className="w-5 h-5 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className="flex-1 ms-3 whitespace-nowrap">Change Password</span>
                </button>
              </li>
              <li>
                <button
                  onClick={handleLogout} 
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 group"
                >
                  <RiLogoutBoxLine className="w-5 h-5 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className="flex-1 ms-3 whitespace-nowrap">Log Out</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ChangePasswordModal 
          isOpen={isModalOpen} 
          onClose={() => setModalOpen(false)}
          BrokerId={broker}
        />
      )}
    </div>
  );
};

export default BrokerView;
