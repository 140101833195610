import React, { useEffect, useState, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/Navbar";
import Topbar from "./pages/searchListing/PropertydetailPage/Topbar";
import Footer from "./components/Footer";
import ProtectedRoute from "./authentication/ProtectedRoutes";
import { useAuth } from "./authentication/AuthContext";
import { Loader } from "./pages/Loader";
import GTMProvider from "./GTMProvider";

import ScrollTracking from "./AppTraking/ScrollTracking";
import { startTimer, stopTimer } from "./AppTraking/localStorageHelper";
import {
  startSession,
  endSession,
  setReturningVisitor,
  getSessionData,
} from "./AppTraking/sessionHelper";
import { trackUser } from "./AppTraking/userHelper";
import { trackUserActivity } from "./AppTraking/trackUserActivity";
import LocationProperty from "./pages/PropertiesFilter/LocationProperty";
import Api from "./api/Api";

// Lazy load components
const Profile = React.lazy(() => import("./pages/Profile"));
const Login = React.lazy(() => import("./authentication/Login"));
const HomePage = React.lazy(() => import("./pages/homepage"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const PropertiesDetails = React.lazy(() =>
  import("./pages/PropertiesDetails/PropertiesDetails")
);
const SearchPage = React.lazy(() => import("./pages/SearchPage"));
const ListingSearch = React.lazy(() =>
  import("./pages/searchListing/ListingSearch")
);
const Listing = React.lazy(() => import("./pages/searchListing/Listing"));
const BlogDetailsPage = React.lazy(() =>
  import("./pages/Blogs/BlogDetailsPage")
);
const Blogs = React.lazy(() => import("./pages/Blogs/Blogs"));
const AboutUs = React.lazy(() => import("./pages/About/AboutUs"));
const Developers = React.lazy(() => import("./pages/Developers/Developers"));
const BrockerLogin = React.lazy(() => import("./Broker/Login"));
const Registration = React.lazy(() => import("./Broker/Registration"));
const BrokerProfile = React.lazy(() => import("./Broker/BrokerProfile"));
const Brokerpropertyspage = React.lazy(() =>import("./Broker/Singlepropertys/Brokerpropertyspage"));
const PropertydetailPage = React.lazy(() =>import("./pages/searchListing/PropertydetailPage/PropertydetailPage"));
const PropertiesList = React.lazy(() =>import("./pages/compare/PropertiesList"));
const Chat = React.lazy(() => import("./pages/chat/chat"));
const ForgotForm=React.lazy(()=>import("./Broker/ForgotForm"));
const ResetForm=React.lazy(()=>import("./Broker/ResetForm"));
const MortgageCalculator=React.lazy(()=>import("./pages/homepage/MortgageCalculators"));
const App = () => {
  return (
    <Router>
      <GTMProvider>
        <Suspense fallback={<Loader />}>
          <AppContent />
        </Suspense>
      </GTMProvider>
    </Router>
  );
};

const AppContent = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCity, setSelectedCity] = useState('');
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await Api.get("/adminproperties/getCity");
        const citiesList = response.data.citylist;
        setCities(citiesList);
        const defaultCity = citiesList.find(city => city.city_Name === "Bengaluru");
        if (defaultCity) {
          setSelectedCity(defaultCity.city_Name);
        }
      } catch (error) {
        console.error("Error fetching cities:", error.message);
      }
    };

    fetchCities();
  }, []);
  
  useEffect(() => {
    const formTimer = setTimeout(() => {
      setShowForm(true);
    }, 30000);

    return () => clearTimeout(formTimer);
  }, []);

  useEffect(() => {
    const formInterval = setInterval(() => {
      setShowForm(true);
    }, 30000);

    return () => clearInterval(formInterval);
  }, []);

  useEffect(() => {
    setShowForm(false);
  }, [location]);

  const handleFormSubmit = () => {
    setShowForm(false);
    setFormSubmitted(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [user]);
  useEffect(() => {
    startTimer();
    const handleBeforeUnload = () => {
      stopTimer();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      stopTimer();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const sessionData = getSessionData();
    if (sessionData.visitorType === "new") {
      setReturningVisitor(); // Mark as returning if not a new visitor
    }
    startSession();
    const handleBeforeUnload = () => {
      endSession();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      endSession();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    trackUser();
    trackUserActivity();
  }, []);
  // useEffect(() => {
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   document.addEventListener('contextmenu', handleContextMenu);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);

  // Uncomment the following line if you want to show the form on specific routes
  // const shouldShowForm = location.pathname === "/" || location.pathname === "/blog";
  const showNavbar =
    location.pathname === "/" ||
    location.pathname === "/blog" ||
    location.pathname === "/Contact-Us" ||
    location.pathname === "/broker/login" ||
    location.pathname === "/blog/:id" ||
    location.pathname === "/about" ||
    location.pathname === "/Registration" ||
    location.pathname === "/Chat" ||
    location.pathname === "/BrokerProfile"||
    location.pathname ==="/login";

  return (
    <>
      <ScrollTracking />
      {showNavbar ? <Navbar onCitySelect={setSelectedCity} cities={cities} selectedCity={selectedCity} /> : <Topbar onCitySelect={setSelectedCity} cities={cities} selectedCity={selectedCity} />}
      <Routes>
        <Route path="/" element={<HomePage  selectedCity={selectedCity} />} />
        <Route path="*" element={<HomePage  selectedCity={selectedCity} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/broker/login" element={<BrockerLogin />} />
        <Route path="/Registration" element={<Registration />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:id" element={<BlogDetailsPage />} />
        <Route path="/Contact-Us" element={<ContactUs />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/properties/:city_Name/:pramotional_Location/:actual_Location/:project_Name/:id"  element={<PropertiesDetails />} />
        <Route path="/ListingSearch" element={<ListingSearch />} />
        <Route path="/Listing" element={<Listing />} />
        <Route path="/PropertydetailPage" element={<PropertydetailPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/Developer/:builder_name/:builderId" element={<Developers />}/>
        <Route path="/Brokerpropertyspage/:BrokerpropertyId" element={<Brokerpropertyspage />}/>
        <Route path="/profile" element={ <ProtectedRoute><Profile />  </ProtectedRoute>}/>
        <Route path="/BrokerProfile" element={ <ProtectedRoute> <BrokerProfile /> </ProtectedRoute> } />
        <Route  path="/PropertiesList/Compare/:PropertyName" element={<PropertiesList />}/>
        <Route path="/LocationProperty/:cityName" element={<LocationProperty />}/>
        <Route path="/LocationProperty/:City_name/:zone" element={<LocationProperty />} />
        <Route path="/LocationProperty/:City_Name/:Zone/:Location" element={<LocationProperty />}/>
        <Route path="/Chat" element={ <ProtectedRoute> <Chat />  </ProtectedRoute>}/>
        <Route path="/forgot-password" element={<ForgotForm />} />
        <Route path="/reset-password" element={<ResetForm />} />
        <Route path="/MortgageCalculator" element={<MortgageCalculator/>}/>
      </Routes>
      <Footer />
      {/* Uncomment the following block if you want to show the form on specific routes
      {showForm && shouldShowForm && !formSubmitted && (
        <FormComponent onSubmit={handleFormSubmit} onClose={handleCloseForm} />
      )} */}
      <ToastContainer />
    </>
  );
};
export default App;
