// sessionHelper.js

export const startSession = () => {
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};
    const currentTime = new Date().toISOString();
    sessionData.startTime = currentTime;
    sessionData.visitorType = sessionData.visitorType || 'new'; // Default to new visitor
    sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
  };
  
  export const endSession = () => {
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};
    const currentTime = new Date().toISOString();
    sessionData.endTime = currentTime;
    sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
  };
  
  export const getSessionData = () => {
    return JSON.parse(sessionStorage.getItem('sessionData')) || {};
  };
  
  export const clearSessionData = () => {
    sessionStorage.removeItem('sessionData');
  };
  
  export const setReturningVisitor = () => {
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};
    sessionData.visitorType = 'returning';
    sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
  };
  