import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const userJson = localStorage.getItem('user');
    return userJson ? JSON.parse(userJson) : null;
  });
  const [broker, setBroker] = useState(() => {
    const brokerJson = sessionStorage.getItem('broker');
    return brokerJson ? JSON.parse(brokerJson) : null;
  });
  const [loading, setLoading] = useState(false);

  const signIn = async (userData) => {
    try {
      setLoading(true);
      setUser(userData);
      sessionStorage.setItem('user', JSON.stringify(userData));
    } catch (error) {
      console.error('Error signing in:', error);
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setLoading(true);
      setUser(null);
      sessionStorage.removeItem('user');
    } catch (error) {
      console.error('Error signing out:', error);
      setLoading(false);
    }
  };

  const loginBroker = (brokerData) => {
    setLoading(true);
    setBroker(brokerData);
    sessionStorage.setItem('broker', JSON.stringify(brokerData));
  };

  const logoutBroker = () => {
    setLoading(true);
    setBroker(null);
    sessionStorage.removeItem('broker');
  };

  const contextValue = {
    user,
    broker,
    loading,
    signIn,
    signOut,
    loginBroker,
    logoutBroker,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
