// export const trackUser = () => {
//     let userId = sessionStorage.getItem('userId');
//     if (!userId) {
//       userId = generateUserId();
//       sessionStorage.setItem('userId', userId);
//     }
//     return userId;
//   };
  
// const generateUserId = () => {
//     const totalUsers = parseInt(sessionStorage.getItem('totalUsers') || '0', 10) + 1;
//     sessionStorage.setItem('totalUsers', totalUsers.toString());
//     return totalUsers.toString();
// };

export const trackUser = () => {
  let userId = sessionStorage.getItem('userId');
  if (!userId) {
      userId = generateUserId();
      sessionStorage.setItem('userId', userId);
  }
  return userId;
};

const generateUserId = () => {
  const totalUsers = parseInt(sessionStorage.getItem('totalUsers') || '0', 10) + 1;
  sessionStorage.setItem('totalUsers', totalUsers.toString());
  const randomString = generateRandomString(6); // Adjust the length as needed
  return `${totalUsers}-${randomString}`;
};

const generateRandomString = (length) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};
